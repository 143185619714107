import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import MainButton from "../ui-components/MainButton";
import Content from "./Content";
import { Link } from 'gatsby'

import "../../styles/cookieBannerComponent.scss";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("cookie-consent");
    if (!consent) setIsVisible(true);
  }, []);

  const acceptCookies = () => {
    Cookies.set("cookie-consent", "accepted", { expires: 365 });
    setIsVisible(false);
  };

  const rejectCookies = () => {
    Cookies.set("cookie-consent", "rejected", { expires: 365 });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
      <Content className="cookie-banner moveInFromBottom">
        <div className="cookie-banner-wrapper">
          <div className='cookie-banner-description'>
            <h2>We Value Your Privacy</h2>
            <p>
              We use cookies to enhance your browsing experience, 
              analyze site traffic and provide content. 
              Some cookies are essential for the site to function, 
              while others help us optimize and improve your experience.
            </p>

            <p>
              You can manage your cookie preferences or learn more about our policies on our <Link to="/imprint">Imprint page</Link>. 
              By clicking "Accept," you consent to our use of cookies. 
              By clicking "Reject," only essential cookies will be used.
            </p>
          </div>
          <div className='cookie-banner-buttons'>
            <MainButton
              styleType="filled"
              color="gradient"
              className="button-wrapper"
              onClick={acceptCookies}>
                Accept
            </MainButton>
            <MainButton
              color="secondary-color"
              className="button-wrapper"
              onClick={rejectCookies}>
                Reject
            </MainButton>
          </div>  
        </div>
      </Content>
  );
};

export default CookieBanner;
