import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import CookieBanner from "./src/components/page-components/CookieBanner"

export const wrapRootElement = ({ element }) => {
  return (
    <ParallaxProvider>
      <CookieBanner/>
      {element}
    </ParallaxProvider>
  )
}
