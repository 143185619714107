import React from "react";
import "../../styles/contentComponent.scss";

const Content = ({ children, className = "" }) => {
  return (
    <div className="content">
      <div className={className}>
        {children}
      </div>
    </div>
  )
};

export default Content;
